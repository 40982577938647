<template>
  <div class="container">
    <AssignmentbarFITi @end-assignment="alert('endAssignment')"></AssignmentbarFITi>
    <div class="animate glow row">
      <h2>FIT-I Abschluss</h2>
      <p>
        Mit dem Fragebogen Fit für das Informatikstudium?! haben Sie den letzten Teil 
        des OSA Informatik hinter sich gebracht. Jetzt können Sie die Ergebnisse der 
        durchgeführten Module abrufen. Gehen Sie zurück zur Hauptseite und 
        schauen Sie sich die Ergebnisse an. Viel Spaß! 
      </p>
     <!--<p>
        Die Ergebnisse der Bewerber sollten wir nach den einzelnen Teilen des OSA in sehr kompakter Form zurückmelden.
      </p>
      <p>
        Die folgenden Fragen werden dann nach dem Abschluss der folgenden Teile des OSA dargeboten:
        <ol>
          <li>Über Informatik: Gegenstand, Inhalte und Studium an der TU Wien</li>
          <li>Sortieren mit System! (Sortieraufgabe, Teil 1: Kartensortieren, Teil 2: Algorithmus notieren)</li>
          <li>Fit für das Informatikstudium?! (FIT-I)</li>
        </ol>
      </p>
      <p>
        Bisher haben Sie in diesem OSA Informationen zur Informatik und zum Studium an der TU Wien erhalten, sich mit speziellen Problemen der Informatik (oder: dem Sortierproblem) vertieft beschäftigt und mit einem Fragebogen Ihre persönlichen Voraussetzungen für das Informatikstudium beurteilt (erforscht, eruiert, ergründet, durchleuchtet, …). Dessen Ergebnisse kennen Sie bereits. Bevor Sie nun Ihre zusammenfassende Auswertung des OSA erhalten, möchten wir Sie noch um einige Einschätzungen und erste persönliche Schlussfolgerungen bitten.
      </p>
      <p>
        Unsere ersten Fragen beziehen sich auf die Nutzung der Informationen zur Informatik und zum Studium sowie die Bearbeitung der Informatik-Aufgabe im OSA durch Sie. Bitte schätzen Sie dazu ein, wie sehr die folgenden Aussagen für Sie zutreffen.
      </p>--> 
    </div>
    <div class="btn-container">
      <div class="custom-btn" @click="forwardBtnClicked">Zum Ergebnis</div>
    </div>
  </div>
</template>

<script>
import AssignmentbarFITi from "@/components/inf/fiti/AssignmentbarFITi.vue";
import * as scrolling from "@/helpers/scroll-helper.js";
import store from "@/store";

export default {
  name: "FITiIntroduction",
  components: {
    AssignmentbarFITi
},
  emits: ["fiti-finished", "conclusion-finished"],
  mounted() {
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" }); 
  },
  data() {
    return {};
  },
  methods: {
    async finishFITi(){
      this.$emit("fiti-finished");
    },
    forwardBtnClicked() {
      store.dispatch("fitiLogPost");
      this.$emit("conclusion-finished");
    },
  },
  computed: {
    test () {
    return store.state.fitiModule
  }
  }
};
</script>

<style>
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
</style>
