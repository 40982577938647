<template>
  <div class="container">
    <AssignmentbarFITi @end-assignment="alert('endAssignment')"></AssignmentbarFITi>
    <div class="animate glow row">
      <h2>Fit für das Informatik-Studium?! (FIT-I)</h2>
      <p>
      Wenn die Entscheidung für ein Studium und ein bestimmtes Fach ansteht, ist es ratsam, 
      sich neben Interesse über seine Eignung Gedanken zu machen. Von guter Eignung sprechen wir, wenn eine Person in hohem Maße über jene persönlichen Voraussetzungen verfügt, 
      die nötig sind, um die Anforderungen von Studium und Beruf erfolgreich zu bewältigen. Im Falle einer guten Passung kann eher davon ausgegangen werden, dass
      </p>

      <p>
        <ul>      
          <li>ein schneller Studienfortschritt gelingt und die Studiendauer kurz gehalten werden kann,</li>
          <li>sich eine stabile Studien- und Berufsmotivation entwickelt, die langfristig Freude am Lernen, an der Arbeit und am Fach sichert,</li> 
          <li>ein guter Studienerfolg erzielt wird, der die Chancen für einen zügigen Einstieg in den Beruf erhöht,</li>
          <li>vermehrt positive Erfahrungen im Studium gemacht werden und das Selbstvertrauen steigt,</li>
          <li>eine Überforderung vermieden wird, die mit gesundheitlichen Beeinträchtigungen und persönlichen Krisen einhergehen kann,</li>
          <li>die Kosten eines Studiums gering gehalten werden.</li>
        </ul>
      </p>

      <p>
      Mit dem folgenden Fragebogen können Sie Selbsteinschätzungen zu Ihren persönlichen Eignungsvoraussetzungen für das Informatikstudium und den Informatikberuf erfassen. 
      Ergänzend haben Sie die Möglichkeit, eine Fremdeinschätzung von einer Person Ihrer Wahl zu Ihrer Selbsteinschätzung zu erhalten und damit Ihre Entscheidungsgrundlage über die Aufnahme eines Studiums abzusichern.
      </p>
    </div>
    <div class="btn-container">
      <div class="custom-btn" @click="forwardBtnClicked">Weiter</div>
    </div>
  </div>
</template>

<script>
import AssignmentbarFITi from "@/components/inf/fiti/AssignmentbarFITi.vue";
import * as scrolling from "@/helpers/scroll-helper.js";

export default {
  name: "FITiIntroduction",
  components: {
    AssignmentbarFITi
},
  emits: ["fiti-finished", "intro-finished"],
  mounted() {
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" }); 
  },
  data() {
    return {};
  },
  methods: {
    async finishFITi(){
      this.$emit("fiti-finished");
    },
    forwardBtnClicked() {
      this.$emit("intro-finished");
    },
  },
};
</script>

<style>
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
</style>
