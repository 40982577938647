<template>
  <!-- 
    MAIN COMPONENT FOR SECURITY ASSIGNMENT 
    on start it shows the tutorial
    after the tutorial is finished or skipped it shows the assignment
    -->
  <div class="container" v-if="this.dataReady">
    <div
      style="
        color: red;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
      "
      v-if="false"
    >
      BUTTONS ARE FOR DEVELOPMENT <br />
      <button @click="startIntroduction">Intro</button>
      <button @click="startExplanation">Explanation</button>
      <button @click="startA1">A1</button>
      <button @click="startA2">A2</button>
      <button @click="startA3">A3</button>
      <button @click="startB1">B1</button>
      <button @click="startB2">B2</button>
      <button @click="startC1">C1</button>
      <button @click="startC2">C2</button>
      <button @click="startD1">D1</button>
      <button @click="startD2">D2</button>
      <button @click="startConclusion">Conclusion</button>
      <button @click="startResults">Results</button>
    </div>

    <div class="error-message" v-if="!this.user">
      Um auf das Modul "Fit für Informatik" zugreifen zu können, müssen Sie
      angemeldet sein.
      <router-link to="/"
        ><div class="custom-btn">Zur Anmeldung</div></router-link
      >
    </div>
    <div class="error-message" v-else-if="this.isFitiCompleted">
      <p>
        Sie haben das Modul "Fit für Informatik" bereits abgeschlossen. Eine
        erneute Bearbeitung ist derzeit nicht möglich.
      </p>
      <router-link to="/informatik"
        ><div class="custom-btn">Zum Informatik OSA</div></router-link
      >
      <div class="fremdeinschaetzung-container">
        <FitiFeComponent></FitiFeComponent>
      </div>
    </div>
    <div v-else>
      <div class="introduction-container" v-if="showIntroduction">
        <Intro @intro-finished="startExplanation"> </Intro>
      </div>

      <div class="explanation-container" v-if="showExplanation">
        <Explanation
          @explanation-finished="startA1"
          @explanation-back="startIntroduction"
        >
        </Explanation>
      </div>

      <div v-if="showA1">
        <A1 @A1-back="startExplanation" @A1-finished="startA2"> </A1>
      </div>

      <div v-if="showA2">
        <A2 @A2-back="startA1" @A2-finished="startA3"> </A2>
      </div>

      <div v-if="showA3">
        <A3 @A3-back="startA2" @A3-finished="startB1"> </A3>
      </div>

      <div v-if="showB1">
        <B1 @B1-back="startA3" @B1-finished="startB2"> </B1>
      </div>

      <div v-if="showB2">
        <B2 @B2-back="startB1" @B2-finished="startB3"> </B2>
      </div>

      <div v-if="showB3">
        <B3 @B3-back="startB2" @B3-finished="startC1"> </B3>
      </div>

      <div v-if="showC1">
        <C1 @C1-back="startB3" @C1-finished="startC2"> </C1>
      </div>

      <div v-if="showC2">
        <C2 @C2-back="startC1" @C2-finished="startD1"> </C2>
      </div>

      <div v-if="showD1">
        <D1 @D1-back="startC2" @D1-finished="startD2"> </D1>
      </div>

      <div v-if="showD2">
        <D2 @D2-back="startD1" @D2-finished="startConclusion"> </D2>
      </div>

      <div v-if="showConclusion">
        <Conclusion
          @conclusion-back="startD2"
          @conclusion-finished="startResults"
        >
        </Conclusion>
      </div>

      <div v-if="showResults">
        <Results @results-back="startConclusion" @results-finished="finishFiti">
        </Results>
      </div>
    </div>
  </div>
</template>

<script>
import Intro from "@/components/inf/fiti/Intro.vue";
import Explanation from "@/components/inf/fiti/Explanation.vue";
import A1 from "@/components/inf/fiti/A1.vue";
import A2 from "@/components/inf/fiti/A2.vue";
import A3 from "@/components/inf/fiti/A3.vue";
import B1 from "@/components/inf/fiti/B1.vue";
import B2 from "@/components/inf/fiti/B2.vue";
import B3 from "@/components/inf/fiti/B3.vue";
import C1 from "@/components/inf/fiti/C1.vue";
import C2 from "@/components/inf/fiti/C2.vue";
import D1 from "@/components/inf/fiti/D1.vue";
import D2 from "@/components/inf/fiti/D2.vue";
import Conclusion from "@/components/inf/fiti/Conclusion.vue";
import Results from "@/components/inf/fiti/Results.vue";
import FitiFeComponent from "@/components/inf/fiti/FitiFeComponent.vue";

import * as myOSAhandler from "@/scripts/inf_my-osa-api-handler.js";

export default {
  components: {
    Intro,
    Explanation,
    A1,
    A2,
    A3,
    B1,
    B2,
    B3,
    C1,
    C2,
    D1,
    D2,
    Conclusion,
    Results,
    FitiFeComponent,
  },
  data() {
    return {
      dataReady: false,
      user: {},
      isFitiCompleted: false,
      showIntroduction: true,
      showExplanation: false,
      showA1: false,
      showA2: false,
      showA3: false,
      showB1: false,
      showB2: false,
      showB3: false,
      showC1: false,
      showC2: false,
      showD1: false,
      showD2: false,
      showConclusion: false,
      showResults: false,
    };
  },
  async mounted() {
    this.user = JSON.parse(window.localStorage.getItem("userData"));
    this.isFitiCompleted = await this.getIsFitiCompleted();
    this.$store.dispatch(
      "setHeaderPageTitle",
      "Online Self Assessment Informatik"
    );
    this.$store.dispatch("setIsUserInAssignment", false); //user opened securityView --> user is in assignment
    this.dataReady = true;
  },
  unmounted: function () {
    this.$store.dispatch("setIsUserInAssignment", false); //user closed securityView --> user is not in assignment anymore
  },
  methods: {
    hideAllComponents() {
      this.showIntroduction = false;
      this.showExplanation = false;
      this.showA1 = false;
      this.showA2 = false;
      this.showA3 = false;
      this.showB1 = false;
      this.showB2 = false;
      this.showB3 = false;
      this.showC1 = false;
      this.showC2 = false;
      this.showD1 = false;
      this.showD2 = false;
      this.showConclusion = false;
      this.showResults = false;
    },
    startIntroduction() {
      this.hideAllComponents();
      this.showIntroduction = true;
    },
    startExplanation() {
      this.hideAllComponents();
      this.showExplanation = true;
    },
    startA1() {
      this.hideAllComponents();
      this.showA1 = true;
    },
    startA2() {
      this.hideAllComponents();
      this.showA2 = true;
    },
    startA3() {
      this.hideAllComponents();
      this.showA3 = true;
    },
    startB1() {
      this.hideAllComponents();
      this.showB1 = true;
    },
    startB2() {
      this.hideAllComponents();
      this.showB2 = true;
    },
    startB3() {
      this.hideAllComponents();
      this.showB3 = true;
    },
    startC1() {
      this.hideAllComponents();
      this.showC1 = true;
    },
    startC2() {
      this.hideAllComponents();
      this.showC2 = true;
    },
    startD1() {
      this.hideAllComponents();
      this.showD1 = true;
    },
    startD2() {
      this.hideAllComponents();
      this.showD2 = true;
    },
    startConclusion() {
      this.hideAllComponents();
      this.showConclusion = true;
    },
    startResults() {
      this.hideAllComponents();
      this.showResults = true;
    },
    async finishFiti() {
      //get my informatik OSA for current user
      const myOSA = await myOSAhandler.getMyInformatikOSA(
        this.$store.getters.getUser.username
      );
      //set securityFinished=true in backend
      await myOSAhandler.finishModule(myOSA.id, { fitIFinished: true });
      this.$router.push("/informatik");
    },
    async getIsFitiCompleted() {
      const myOSA = await myOSAhandler.getMyInformatikOSA(
        this.$store.getters.getUser.username
      );
      return myOSA.fitIFinished;
    },
  },
};
</script>

<style scoped></style>
