<template>
  <div class="container row justify-content-center align-items-center">
    <div class="col-12">
      <h5>
        Sie können eine Email Adresse angeben, an die Sie eine Einladung
        verschicken wollen (optional).
      </h5>
      <form @submit.prevent="sendInvitation">
        <div class="email-form-container">
          <div class="form-group">
            <div class="col-md-6">
              <label for="inputEmail" class="form-label">Email</label>
              <input
                type="email"
                v-model="email"
                class="form-control custom-input"
                id="inputEmail"
                placeholder="Email (optional)"
              />
            </div>
          </div>
        </div>
        <h5>Informationen zur einschätzenden Person (optional)</h5>
        <p>
          Diese Informationen dienen zur Identifikation der Fremdeinschätzung in Ihren Ergebnissen. Sie sind nur für Sie sichtbar und werden der
          einschätzenden Person nur angezeigt, sollten Sie mit dieser ein Auswertungsgespräch führen.
        </p>
        <div class="form-group">
          <div class="form-group">
            <label for="name">Name</label>
            <input
              type="text"
              class="form-control custom-input"
              id="name"
              v-model="name"
            />
          </div>
          <label for="role">Rolle</label>
          <select class="form-select custom-select" id="role" v-model="role">
            <option selected>Elternteil</option>
            <option>Schwester/Bruder</option>
            <option>Sonstiges Familienmitglied</option>
            <option>Partner:in</option>
            <option>Freund:in</option>
            <option>Lehrer:in</option>
            <option>Kolleg:in</option>
            <option>Andere</option>
          </select>
        </div>
        <div class="form-group">
          <label for="age">Alter</label>
          <input
            type="number"
            class="form-control custom-input"
            id="age"
            v-model="age"
          />
        </div>
        <div class="form-group">
          <label for="expertise"
            >Wie beurteilen Sie das Fachwissen dieser Person über
            Informatik?</label
          >
          <select
            class="form-select custom-select"
            id="expertise"
            v-model="expertise"
          >
            <option>Sehr umfassend</option>
            <option>Umfassend</option>
            <option>Ausreichend</option>
            <option>Begrenzt</option>
            <option>Keine Kenntnisse</option>
            <option>Unbekannt</option>
          </select>
        </div>
        <div class="form-group">
          <label for="context"
            >Welchen Kontakt haben Sie mit dieser
            Person hauptsächlich?</label
          >
          <select
            class="form-select custom-select"
            id="context"
            v-model="context"
          >
            <option>Persönlich</option>
            <option>Beruflich</option>
            <option>Ausbildungsbezogen</option>
            <option>Sonstige</option>
          </select>
        </div>
        <div class="form-group">
          <label for="context"
            >Wie oft sehen und unterhalten Sie sich mit dieser Person?</label
          >
          <select
            class="form-select custom-select"
            id="interaction"
            v-model="interaction"
          >
            <option>täglich</option>
            <option>mehrmals wöchentlich</option>
            <option>wöchentlich</option>
            <option>mehrmals im Monat</option>
            <option>monatlich</option>
            <option>weniger als monatlich</option>
          </select>
        </div>
        <div style="display:flex; justify-content:right; margin-top: 1em"><button type="submit" class="btn custom-btn" >
          Fremdeinschätzung erstellen
        </button></div>
        
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
const emit = defineEmits(["fitiFeCreated"]);

const email = ref("");
const name = ref("");
const role = ref("");
const age = ref(0);
const expertise = ref("");
const context = ref("");
const interaction = ref("");

const sendInvitation = () => {
  alert("Fremdeinschätzung erstellt.")
  createFremdeinschätzungRecord();
};

const createFremdeinschätzungRecord = async () => {
  //alert("Fremdeinschätzung erstellt")
  //sendInvitation();

  if (email.value === "") {
    email.value = null;
  }
  let params = {
    email: email.value,
    name: name.value,
    role: role.value,
    age: age.value,
    expertise: expertise.value,
    context: context.value,
    interaction: interaction.value,
  };
  let objJSON = JSON.stringify({ data: params });

  try {
    await fetch(process.env.VUE_APP_STRAPI_API_URL + "/api/inf-fiti-fes", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
      },
      body: objJSON,
    });
  } catch (error) {
    console.error(error);
  }

  emit("fitiFeCreated");
};
</script>

<style scoped>
.email-form-container {
  margin-bottom: 2em;
}
</style>
