<template>
  <div class="container">
    <AssignmentbarFITi @end-assignment="alert('endAssignment')"
    :showHelpBtn="false"
    :showDismissBtn="true"
    :showFinishBtn="false">
    </AssignmentbarFITi>
    <div class="row">
      <h2>A3. Ausdauer und Beharrlichkeit</h2>
      <p>
        Am Anfang vieler Projekte sehen sich Informatiker:innen oftmals vor „unlösbaren“ Aufgaben stehen.
         Im Verlauf der Arbeit stellt sich nicht selten das Gefühl ein, trotz starken Bemühens auf der Stelle zu treten und immer neue Schwierigkeiten bewältigen zu müssen, 
         ohne dem Ziel wesentlich näher zu kommen. Um dennoch die vorgegebenen gesetzten Ziele zu erreichen, ist es deshalb wichtig, nicht aufzugeben und mit Ausdauer und Beharrlichkeit zu arbeiten.
      </p>
      <div class = "survey">
        <div class="survey-row header">
          <div class = "question">Wie ist das bei Ihnen?</div>
          <div class = "reply">trifft völlig auf mich zu</div>
          <div class = "reply">trifft überwiegend auf mich zu</div>
          <div class = "reply">trifft teilsteils auf mich zu</div>
          <div class = "reply">trifft überwiegend nicht auf mich zu</div>
          <div class = "reply">trifft überhaupt nicht auf mich zu</div>
        </div>

        <div class="survey-row light">
          <div class = "question">Ausdauer ist eine meine großen Stärken.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q1"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Langes Knobeln ist mir mehr Qual als Freude.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q2"></div>
        </div>

        <div class="survey-row light">
          <div class = "question">Ich habe viel mehr Ausdauer als die meisten anderen.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q3"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Ich verliere bald die Lust, wenn ich nicht zügig zum Ergebnis komme.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q4"></div>
        </div>

        <div class="survey-row light">
          <div class = "question">Auch wenn ich Probleme mehrfach nicht lösen kann, bleibe ich aktiv. </div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q5"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Ich tue oft mehr, als erforderlich ist. </div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q6"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q6"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q6"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q6"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q6"></div>
        </div>

    </div>
    <div class="btn-container">
      <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
      <div class="custom-btn" @click="forwardBtnClicked" v-if="isComplete">Weiter</div>
    </div>
  </div>
</div>
</template>

<script>
import AssignmentbarFITi from "@/components/inf/fiti/AssignmentbarFITi.vue";
import * as scrolling from "@/helpers/scroll-helper.js";
import store from "@/store";

export default {
  name: "FITiIntroduction",
  components: {
    AssignmentbarFITi
},
  emits: ["fiti-finished", "A3-back", "A3-finished"],
  mounted() {
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" }); 
    this.fragen.Q1 = this.$store.state.fitiModule.replies.A3[0];
    this.fragen.Q2 = this.$store.state.fitiModule.replies.A3[1];
    this.fragen.Q3 = this.$store.state.fitiModule.replies.A3[2];
    this.fragen.Q4 = this.$store.state.fitiModule.replies.A3[3];
    this.fragen.Q5 = this.$store.state.fitiModule.replies.A3[4];
    this.fragen.Q6 = this.$store.state.fitiModule.replies.A3[5];
  },
  data() {
    return {
      fragen: {
        Q1: null,
        Q2: null,
        Q3: null,
        Q4: null,
        Q5: null,
        Q6: null
      }
    };
  },
  methods: {
    async finishFITi(){
      this.$emit("fiti-finished");
    },
    backBtnClicked() {
      this.$emit("A3-back");
    },
    forwardBtnClicked() {
      this.logUpdate(this.fragen);
      this.$emit("A3-finished");
    },
    logUpdate(data) {
      store.commit("fitiA3Update", data);
    }
  },
  computed: {
    isComplete() {
      return this.fragen.Q1 && this.fragen.Q2 && this.fragen.Q3 && this.fragen.Q4 && this.fragen.Q5 && this.fragen.Q6;
    }
  }
}
</script>

<style>
  .survey {
  display: flex;
  flex-direction: column;
  }
  .survey-row {
    display: flex;
    flex-direction: row;
  }
  .question {
    min-width: 100px;
    width: 25%;
    max-width: 500px;
  }
  .reply {
    min-width: 100px;
    width: 15%;
    max-width: 300px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .dark {
    background-color: rgb(173, 201, 230);
  }
  .light{
    background-color: rgb(248, 248, 248);
  }
  .header {
    background: rgb(70, 110, 165);
    color: white;
  }
</style>