<template>
  <div class="container">
    <AssignmentbarFITi @end-assignment="alert('endAssignment')"></AssignmentbarFITi>
    <div class="animate glow row">
      <h2>FIT für das Informatikstudium?! (FIT-I) </h2>
      <p>
        Der Fragebogen „FIT für das Informatikstudium?!“ richtet sich an Personen, die die Aufnahme eines Informatikstudiums und den Beruf Informatiker/-in für sich in Betracht ziehen. Er soll sie dabei unterstützen, sich ein Bild von den beruflichen Anforderungen und ihren persönlichen Eignungsvoraussetzungen zu machen. Mit dem Einholen einer Fremdeinschätzung bietet sich die Möglichkeit, die Selbsteinschätzung um eine zusätzliche Perspektive zu ergänzen. Die in diesem Prozess gewonnen Erkenntnisse bilden Anregung, um die persönliche Eignung für das Informatikstudium nicht nur zu überprüfen, sondern sie durch gezielte Arbeit weiterzuentwickeln.
      </p>
      <h3>1. Ihr Ergebnisprofil</h3>
      <p>
        Bei der Beantwortung des Fragebogens hatten Sie Selbsteinschätzungen zu 10 berufsrelevanten Merkmalen abgegeben. Das Ergebnis dieser Selbsteinschätzung liegt nun als Grafik vor. Was lässt sich daraus ablesen? Das in der Grafik dargestellte Ergebnisprofil gibt Ihnen darüber Auskunft, wo Sie Ihre Stärken und Schwächen mit Blick auf das Informatikstudium und den Beruf sehen. Es weist für jedes der 10 Merkmale einen Zahlenwert zwischen 1 und 9 aus, wobei höhere Werte stets eine stärkere Merkmalsausprägung (aber nicht zwangsläufig bessere Eignung) bedeuten.
      </p>
      <p>
        Wann immer man Stärken und Schwächen einschätzt, braucht man eine Vergleichsgruppe. Wie Sie z. B. Ihre Leistung im 100-Meter-Lauf bewerten, hängt auch davon ab, mit welcher Personengruppe Sie sich vergleichen. Und auch die Werte Ihrer hier vorliegenden Selbsteinschätzung werden erst aussagekräftig, wenn sie mit einer sinnvollen Vergleichsgruppe in Beziehung gesetzt werden. Die hier benutzte neunstufige Skala (allgemeine Erläuterung siehe Kasten) erlaubt hier einen Vergleich mit 183 Studierenden der Informatik und Wirtschaftsinformatik an der TU Wien. 
      </p>
      <p>
        Was bedeutet das konkret für Ihr Ergebnis? Wenn Sie Werte zwischen 7 und 9 haben, dann bescheinigen Sie sich für diese Merkmale eine überdurchschnittliche Ausprägung – und zwar im Vergleich zur angegebenen Gruppe. Mit Werten zwischen 4 und 6 weisen Sie eine durchschnittliche Ausprägung im Vergleich zu dieser Gruppe auf. Bei Werten zwischen 1–3 liegen Sie unter dem Durchschnitt. Damit wird deutlich, dass alle Merkmale, die einen Wert zwischen 7 und 9 bzw. 1 und 3 haben, Ihre besondere Aufmerksamkeit verdienen. In diesen Bereichen weisen Sie persönliche Stärken auf oder es zeigen sich Schwachpunkte, die auf erforderliche Entwicklungsbemühungen hinweisen, um im Informatikstudium und später im Beruf zu bestehen.
      </p>
      <p class = "box">
        Die hier verwendete neunstufige Standardskala baut auf der Erkenntnis auf, dass sich die meisten Merkmale in einer Gruppe normal verteilen, also entsprechend der „Gaußschen Glockenkurve“. Auch die Merkmale der Studierenden, die in die Entwicklung des FIT-I einbezogen wurden, verteilen sich in dieser Weise. Der Mittelwert auf dieser Skala ist 5. Auch die Werte 4 und 6 sind noch mittlere und somit durchschnittliche Werte. Insgesamt gilt der Durchschnittsbereich (4–6) für 54 % aller Personen. Die Werte 1–3 sind unterdurchschnittliche und 7–9 überdurchschnittliche Werte. Sie gelten für jeweils 23 % der Personen. Die mittleren Werte kommen also weitaus häufiger vor als sehr geringe oder sehr hohe Werte. So liegen beispielsweise die Werte 1 und 9 nur bei 4 % der gewählten Bezugsgruppe vor.
      </p>


      <canvas id="chart" style="width:100%; max-height: 800px;"></canvas>

      <h3>2. Auswertung Ihres Profils nach vier Bereichen</h3>
        <p>Bei der Betrachtung der Grafik werden Sie festgestellt haben, dass miteinander verwandte Merkmale zu inhaltlichen Schwerpunkten zusammengefasst wurden. Es lassen sich vier Bereiche unterscheiden, die als persönliche Eignungsvoraussetzungen für das Studium der Informatik und darüber hinaus auch für den Beruf von großer Bedeutung sind: A. Interesse und Leistungsanspruch, B. Auffassungsgabe und Arbeitsweise, C. Kooperationsbereitschaft und Kontaktfähigkeit und D. Belastbarkeit.</p>

        <div class = "erklaerungsbox" v-if="this.dataReady">
          <div class = "bereiche">
            <div @click = "hideAllBereiche(); bereiche.interesse = true;" :class = "{active: bereiche.interesse}" class = "bereich">
              <div class = "letter">A</div> 
              <div class = "bereichsname">Interesse und Leistungsanspruch</div>
            </div>
            <div @click = "hideAllBereiche(); bereiche.auffassung = true;" class = "bereich" :class = "{active: bereiche.auffassung}">
              <div class = "letter">B</div>
              <div class = "bereichsname">Auffassungsgabe und Arbeitsweise</div>
              </div>
            <div @click = "hideAllBereiche(); bereiche.kooperation = true;" class = "bereich" :class = "{active: bereiche.kooperation}">
              <div class = "letter">C</div> 
              <div class = "bereichsname">Kooperationsbereitschaft und Kontaktfähigkeit</div>
            </div>
            <div @click = "hideAllBereiche(); bereiche.belastbarkeit = true;" class = "bereich" :class = "{active: bereiche.belastbarkeit}">
              <div class = "letter">D</div> 
              <div class = "bereichsname">Belastbarkeit</div>
            </div>
          </div>
          <div class = "erklaerung" id = "erklaerung">
            <div v-if = "bereiche.interesse">
              <h4>A. Interesse und Leistungsanspruch</h4>
              <p>(Merkmale: A1. Neugier und Begeisterungsfähigkeit, A2. Gewissenhaftigkeit und Genauigkeit, A3. Ausdauer und Beharrlichkeit) </p>
              <p>Die drei dem Bereich Interesse und Leistungsanspruch zugeordneten Merkmale stellen sehr grundlegende persönliche Eignungsvoraussetzungen für ein erfolgreiches Studium der Informatik und die spätere Tätigkeit im Beruf dar. In jedem Fall sollten ausgeprägte Neugier und Begeisterungsfähigkeit für die Inhalte der Informatik vorhanden sein, ohne die sich keine anhaltende Freude am Studium und an der späteren beruflichen Tätigkeit in diesem Fachgebiet entwickeln kann. Neugier und Begeisterungsfähigkeit allein machen jedoch keinen/keine Informatiker/-in aus: Hohe Leistungen können nur erreicht werden, wenn Gewissenhaftigkeit und Genauigkeit die Arbeitshaltung kennzeichnen und die Zielsetzungen mit viel Ausdauer und Beharrlichkeit verfolgt werden. Ohne Frage sind in allen drei Merkmalen höhere Werte wünschenswert.</p>
              <p>Was können Sie tun, um sich Ihrer Interessen und Ihres Leistungsanspruchs zu versichern und diese weiterzuentwickeln? Einige mögliche Ansatzpunkte seien genannt:</p>

              <ul>
                <li>Lesen Sie einführende Literatur zur Informatik! Prüfen Sie, ob es ihnen gelingt, den Darstellungen mit Verständnis zu folgen und ob Ihr Interesse durch das neu erworbene Wissen zunimmt.</li>
                <li>Beschäftigen Sie sich mit technischen und gesellschaftlichen Herausforderungen und versuchen Sie herauszufinden, welche Rolle die Informatik bei deren Lösung spielt! Können Sie sich für derartige Fragen und Probleme begeistern?</li>
                <li>Überprüfen Sie Ihren persönlichen Leistungsanspruch! Deckt sich Ihr Anspruch an die Qualität Ihrer Arbeitsergebnisse mit dem geforderten oder geht er gar darüber hinaus? Holen Sie Rückmeldungen von anderen Menschen ein und lassen Sie sich gezielt Feedback geben, wenn Sie sich nicht sicher sind.</li>
                <li>Erinnern Sie sich an Situationen, in denen Sie über eine längere Zeit stark gefordert waren, um bestimmte Ziele zu erreichen. Wie ist es Ihnen gelungen, Ausdauer und Beharrlichkeit zu entwickeln und aufrecht zu erhalten? Warum (nicht)? Welche Schlussfolgerung können Sie daraus für Ihre Studienwahlentscheidung ableiten?</li>
              </ul>

              <p>Sie haben in den dem Bereich zugeordneten Merkmalen wie folgt abgeschnitten</p>
              <ul>
                <li>A1. Neugier und Begeisterungsfähigkeit: Stanine-Wert von {{ this.stanine[0].value }}, d.h. {{ this.returnAverageComparison(this.stanine[0].value) }}</li>
                <li>A2. Gewissenhaftigkeit und Genauigkeit: Stanine-Wert von {{ this.stanine[1].value }}, d.h. {{ this.returnAverageComparison(this.stanine[1].value) }}</li>
                <li>A3. Ausdauer und Beharrlichkeit: Stanine-Wert von {{ this.stanine[2].value }} d.h. {{ this.returnAverageComparison(this.stanine[2].value) }}</li>
              </ul>

            </div>
            <div v-if = "bereiche.auffassung">
              <h4>B. Auffassungsgabe und Arbeitsweise</h4>
              <p>(Merkmale: B1. Auffassungsgabe und analytisches Denken, B2. Selbstständigkeit und Eigenaktivität, B3. Fähigkeit zum effizienten Arbeiten) </p>
              <p>Gute kognitive Fähigkeiten und ein ökonomisches Arbeiten sind eine weitere Voraussetzung für ein erfolgreiches Studium. In der Informatik sind besonders eine gute Auffassungsgabe und ausgeprägtes analytisches Denken erforderlich, um Probleme systematisch durchdringen und geeignete Lösungen erarbeiten zu können. Die inhaltliche Auseinandersetzung mit den Studien- und Arbeitsanforderungen sollte ihre Ergänzung auch in einer entsprechenden Arbeitsweise finden. Gefordert sind Selbstständigkeit und Eigenaktivität, um mehr Tiefe bei der Beschäftigung mit den Inhalten zu erreichen, sowie ein effizientes Arbeiten. Deshalb sollten auch in diesen Merkmalen höhere Werte vorliegen.</p>
              <p>Um sich in diesen Bereichen weiterzuentwickeln, können folgende Anregungen hilfreich sein:</p>

              <ul>
                <li>Beschäftigen Sie sich mit mathematischen Problemen! Finden Sie Freude am abstrakten Denken? Suchen Sie nach Problemen im Alltag, die mathematisch gelöst werden können, und entwickeln Sie Lösungsmodelle dafür.</li>
                <li>Überprüfen Sie Ihre Arbeitsweise! Verstehen Sie die Vorgaben und Anleitungen zur Lösung von Aufgaben als Rahmen, den Sie erweitern können, um zu neuen Erkenntnissen zu gelangen.</li>
                <li>Arbeiten Sie effizient genug, um ausreichend Zeit für die Bewältigung eines größeren Arbeitsanfalls und für andere (persönliche) Dinge zu haben? Machen Sie sich bewusst, wie viel Zeit Sie für welche Tätigkeiten aufwenden.</li>
                <li>Verbessern Sie Ihre persönliche Arbeitsorganisation! Tauschen Sie sich mit anderen aus. Eignen Sie sich effektivere Arbeitstechniken an, üben Sie sich im Zeitmanagement und der Prioritätensetzung. Stärken Sie Ihre Selbstdisziplin bei der Planung und Realisierung von Aufgaben.</li>
              </ul>

              <p>Sie haben in den dem Bereich zugeordneten Merkmalen wie folgt abgeschnitten</p>
              <ul>
                <li>B1. Auffassungsgabe und analytisches Denken: Stanine-Wert von <strong>{{ this.stanine[3].value }}</strong>, d.h. {{ this.returnAverageComparison(this.stanine[3].value) }}</li>
                <li>B2. Selbstständigkeit und Eigenaktivität: Stanine-Wert von <strong>{{ this.stanine[4].value }}</strong>, d.h. {{ this.returnAverageComparison(this.stanine[4].value) }}</li>
                <li>B3. Fähigkeit zum effizienten Arbeiten: Stanine-Wert von <strong>{{ this.stanine[5].value }}</strong>, d.h. {{ this.returnAverageComparison(this.stanine[5].value) }}</li>
              </ul>
            </div>
            <div v-if = "bereiche.kooperation">
              <h4>C. Kooperationsbereitschaft und Kontaktfähigkeit</h4>
              <p>(Merkmale: C1. Bereitschaft zur Zusammenarbeit, C2. Soziales Geschick und Sensibilität)</p>
              <p>Es wäre falsch zu glauben, dass Studierende der Informatik und Informatiker/-innen ihre Arbeitszeit allein vor dem Bildschirm verbringen. Vielmehr ist in der Regel eine enge Zusammenarbeit mit anderen gefordert. Eine ausgeprägte Bereitschaft dazu stellt eine wichtige Voraussetzung für eine erfolgreiche Bewältigung der Studien- und beruflichen Anforderungen dar. Gefordert sind daneben soziales Geschick und Sensibilität. Dies gilt in besonderem Maße, wenn anderen Berufsgruppen Probleme aus der Sicht der Informatik vermittelt werden sollen und ein gemeinsames Verständnis für deren Lösung zu finden ist. Deshalb sollten auch im sozial-kommunikativen Bereich deutliche Stärken vorhanden sein, die in höheren Werten im Profil ihren Ausdruck finden.</p>
              <p>Folgende Bemühungen können angebracht sein, um sich in diesem Bereich weiterzuentwickeln:</p>

              <ul>
                <li>Suchen Sie die Zusammenarbeit mit anderen! Arbeiten Sie in Projekten mit. Bringen Sie sich in Diskussionen ein und versuchen Sie Lösungen zu finden.</li>
                <li>Hören Sie anderen bewusst und aufmerksam zu! Stellen Sie Fragen, um Ihr Gegenüber besser zu verstehen.</li>
                <li>Üben Sie sich in der Gesprächsführung! Lernen Sie, in Diskussionen unterschiedliche Standpunkte zu akzeptieren, auszutauschen und zusammenzuführen.</li>
                <li>Vergraben Sie sich nicht hinter Ihrem Computer! Suchen Sie den direkten Kontakt zu anderen.</li>
                <li>Suchen Sie ein Forum oder eine Plattform im Internet auf, die eines ihrer Lieblingsthemen behandelt, in denen Sie sich gut auskennen. Unterstützen Sie andere dort, indem Sie deren Fragen beantworten oder bei der Lösung von Problemen helfen.</li>
              </ul>

              <p>Sie haben in den dem Bereich zugeordneten Merkmalen wie folgt abgeschnitten</p>
              <ul>
                <li>C1. Bereitschaft zur Zusammenarbeit: Stanine-Wert von <strong>{{ this.stanine[6].value }}</strong>, d.h. {{ this.returnAverageComparison(this.stanine[6].value) }}</li>
                <li>C2. Soziales Geschick und Sensibilität: Stanine-Wert von <strong>{{ this.stanine[7].value }}</strong>, d.h. {{ this.returnAverageComparison(this.stanine[7].value) }}</li>
              </ul>
            </div>
            <div v-if = "bereiche.belastbarkeit">
              <h4>D. Belastbarkeit</h4>
              <p>(Merkmale: D1. Resignationstendenz (bei Misserfolg), D2. Erholungs- & Entspannungsfähigkeit)</p>
              <p>Für die meisten Menschen gilt, dass ihnen im Studium wie auch im Beruf nicht alle Vorhaben uneingeschränkt gelingen. Misserfolge und Rückschläge werden wahrscheinlich nicht zur Gänze ausbleiben. Umso wichtiger ist es, nicht zu resignieren und in Passivität zu verfallen, wenn es nicht wie gewünscht läuft. Gute Erholungs- und Entspannungsfähigkeit sind wichtig für die Erhaltung der Leistungsfähigkeit und die langfristige Gewährleistung der Gesundheit. Eine hohe Ausprägung in der Resignationstendenz sowie eine geringe in der Erholungs- und Entspannungsfähigkeit lassen hier auf mögliche Defizite schließen. Auf der anderen Seite ist es aber nicht so, dass die jeweils niedrigste bzw. höchste Ausprägung in jedem Fall erforderlich ist.</p>
              <p>Was kann getan werden, wenn die Werte stärkere Einschränkungen in der psychischen Widerstandskraft und im gesundheitsförderlichen Verhalten anzeigen? Einige mögliche Ansatzpunkte seien genannt:</p>

              <ul>
                <li>Suchen Sie das Gespräch mit anderen (Mitschüler:innen, Kolleg:innen usw.) und tauschen Sie sich darüber aus, wie diese Misserfolge und Rückschläge erleben und versuchen zu überwinden! Über diesen gegenseitigen Austausch lassen sich emotionale Entlastung finden und das Erleben sozialer Unterstützung stärken.</li>
                <li>Gehen Sie belastende Situationen gezielt an! Üben Sie die Bewältigung kritischer Situationen (z. B. Vorträge, Präsentationen, …), um auf diese Weise kontinuierlich an Sicherheit zu gewinnen. Auch Prüfungsangst kann entgegengewirkt werden!</li>
                <li>Wenn Sie dazu neigen, schnell aufzugeben, versuchen Sie, dem entgegenzuwirken. Erinnern Sie sich an Situationen, in denen das nicht so war. Was war da anders? Nutzen Sie die gewonnene Erkenntnis, um sich in zukünftigen Situationen anders verhalten zu können.</li>
                <li>Suchen Sie frühzeitig Hilfe und nehmen Sie alle mögliche Unterstützung in Anspruch, bevor sich die Schwierigkeiten anhäufen! Es gibt auch viele Möglichkeiten professioneller Unterstützung für Schüler/-innen und Studierende, die auch kostenfrei in Anspruch genommen werden können.</li>
                <li>Klären Sie Ihre persönlichen Ansprüche, Erwartungen und Haltungen gegenüber Arbeit und Studium! Ein ausgewogenes Verhältnis zwischen Arbeit und anderen Lebensanforderungen zu finden, ist eine wichtige Grundlage für die langfristige Erhaltung der Gesundheit (s. a. B. Auffassungsgabe und Arbeitsweise).</li>
                <li>Lernen Sie, sich zu entspannen! Pflegen Sie sportliche und weitere Freizeitaktivitäten und achten Sie auf Erholungs- und Regenerationsphasen im Alltag.</li>
              </ul>

              <p>Sie haben in den dem Bereich zugeordneten Merkmalen wie folgt abgeschnitten</p>
              <ul>
                <li>D1. Resignationstendenz (bei Misserfolg): Stanine-Wert von <strong>{{ this.stanine[8].value }}</strong>, d.h. {{ this.returnAverageComparison(this.stanine[8].value) }}</li>
                <li>D2. Erholungs- & Entspannungsfähigkeit: Stanine-Wert von <strong>{{ this.stanine[9].value }}</strong>, d.h. {{ this.returnAverageComparison(this.stanine[9].value) }}</li>
              </ul>
            </div>
          </div>
        </div>
      <p></p>

      <h3>3. Ergänzung der Selbsteinschätzung durch eine Fremdeinschätzung</h3>
      <p>Bei Selbsteinschätzungen – gerade auch, wenn es um so wichtige Fragen wie die Studien- bzw. berufliche Eignung geht – ist das Ergebnis selten objektiv. Einerseits besteht die Neigung, sich in zu günstigem Licht zu sehen. Andererseits gibt es auch die umgekehrte Tendenz zur überkritischen Sicht auf die eigene Person. In beiden Fällen kann das Urteil einer außenstehenden Person hilfreich sein, denn es erlaubt den Vergleich von Selbst- und Fremdbild. </p>
      <p>Aus diesem Grunde gibt das Verfahren die Gelegenheit zur Fremdeinschätzung. Bitten Sie eine Person, die Sie gut kennt, die Fremdeinschätzungsversion von FIT-I zu bearbeiten. Am ehesten dürften hier Mitschüler/-innen, Studienkolleginnen/-kollegen, Lehrende, Eltern, ... in Frage kommen. Wenn Sie sich nach erfolgter Fremdeinschätzung wieder in das System einloggen, finden Sie in der Profildarstellung die grafische Gegenüberstellung Ihrer Selbst- und der Fremdeinschätzung. (Der/Die Fremdeinschätzer/-in hat diese Grafik nicht zur Verfügung. Er/Sie lernt Ihre Selbsteinschätzung erst dann kennen, wenn Sie ihm/ihr diese in einem Auswertungsgespräch, zu dem wir raten, zugänglich machen.) Weiterhin werden Ihnen noch einige Hinweise zum Auswertungsgespräch mit dem/der Fremdeinschätzer/-in gegeben.</p>
      <p class = "footnote"></p>
    </div>
    <div class="btn-container">
      <div class="custom-btn" @click="finishFITi()">Modul beenden</div>
    </div>
  </div>
</template>

<script>
import AssignmentbarFITi from "@/components/inf/fiti/AssignmentbarFITi.vue";
import * as scrolling from "@/helpers/scroll-helper.js";
import Chart from "chart.js/auto";

export default {
  name: "FITiIntroduction",
  components: {
    AssignmentbarFITi
  },
  emits: ["fiti-finished"],
  data() {
    return {
      dataReady: false,
      replies: null,
      bereiche: {
        interesse: true,
        auffassung: false,
        kooperation: false,
        belastbarkeit: false
      },
      subbereiche: ['A1', 'A2', 'A3', 'B1', 'B2', 'B3', 'C1', 'C2', 'D1', 'D2'],
      // TODO: Stanine-Werte anpassen
      berechnungen: {
        A1: {name: "Neugier und Begeisterungsfähigkeit", summe: [1,1,1,1,-1,1], konstante: 6, stanine: [2.83, 3.33, 3.67, 4.00, 4.33, 4.67, 4.83, 5]},
        A2: {name: "Gewissenhaftigkeit und Genauigkeit", summe: [1,1,-1,1,1], konstante: 6, stanine: [2.20, 2.80, 3.40, 3.80, 4.20, 4.60, 4.99, 5]},
        A3: {name: "Ausdauer und Beharrlichkeit", summe: [1,-1,1,-1,1,1], konstante: 12, stanine: [2.33, 2.83, 3.33, 3.67, 4.00, 4.33, 4.67, 5]},
        B1: {name: "Auffassungsgabe und analytisches Denken", summe: [1,1,-1,1,1], konstante: 6, stanine: [2.80, 3.00, 3.40, 3.60, 4.00, 4.20, 4.60, 4.80]},
        B2: {name: "Selbstständigkeit und Eigenaktivität", summe: [-1,-1,-1,-1,-1], konstante: 30, stanine: [1.60, 2.20, 2.80, 3.40, 3.80, 4.00, 4.40, 4.80]},
        B3: {name: "Fähigkeit zum effizienten Arbeiten", summe: [1,-1,1,-1,1,-1], konstante: 18, stanine: [2.33, 2.67, 3.00, 3.33, 3.67, 4.00, 4.33, 4.50]},
        C1: {name: "Bereitschaft zur Zusammenarbeit", summe: [1,1,1,-1,1], konstante: 6, stanine: [2.00, 2.60, 3.20, 3.60, 4.00, 4.40, 4.80, 5]},
        C2: {name: "Soziales Geschick und Sensibilität", summe: [1,1,1,-1,1], konstante: 6, stanine: [2.40, 2.80, 3.20, 3.60, 4.00, 4.40, 4.80, 5]},
        D1: {name: "Resignationstendenz (bei Misserfolg)", summe: [1,1,1,1,-1], konstante: 6, stanine: [1.40, 1.80, 2.20, 2.80, 3.00, 3.60, 4.00, 4.40]},
        D2: {name: "Erholungs- & Entspannungsfähigkeit", summe: [1,-1,-1,1,1], konstante: 12, stanine: [1.80, 2.00, 2.40, 3.00, 3.60, 4.00, 4.60, 5]}
      },
      stanine: [ 
        { "label": "A1", "name": "Neugier und Begeisterungsfähigkeit", "value": 9 }, 
        { "label": "A2", "name": "Gewissenhaftigkeit und Genauigkeit", "value": 9 }, 
        { "label": "A3", "name": "Ausdauer und Beharrlichkeit", "value": 9 }, 
        { "label": "B1", "name": "Auffassungsgabe und analytisches Denken", "value": 9 }, 
        { "label": "B2", "name": "Selbstständigkeit und Eigenaktivität", "value": 9 }, 
        { "label": "B3", "name": "Fähigkeit zum effizienten Arbeiten", "value": 9 }, 
        { "label": "C1", "name": "Bereitschaft zur Zusammenarbeit", "value": 9 }, 
        { "label": "C2", "name": "Soziales Geschick und Sensibilität", "value": 9 }, 
        { "label": "D1", "name": "Resignationstendenz (bei Misserfolg)", "value": 9 }, 
        { "label": "D2", "name": "Erholungs- & Entspannungsfähigkeit", "value": 9 } 
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          r: {
            beginAtZero: true,
            min: 0,
            max: 10
          }
        }
      }
    };
  },
  /*async beforeMount() {
    console.log("Before Mount:");
    console.log(this.replies);
    this.replies = this.$store.state.fitiModule.replies;
    console.log("From store:");
    console.log(this.replies);
    await this.getMyResultFiti();
    this.assignStanine();
    console.log("After assignStanine():")
    console.log(this.stanine);
  },*/
  async mounted() {
    console.log("Mounted:");
    console.log(this.replies);
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" }); 
    await this.getMyResultFiti();
    this.assignStanine();
    this.dataReady = true;
    new Chart("chart", this.dataset);
  },
  methods: {
    async finishFITi(){
      this.$emit("results-finished");
    },
    async getMyResultFiti() {
      try {
        const response = await fetch(
          process.env.VUE_APP_STRAPI_API_URL +
            "/api/inf-fiti-log/myResults",
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
          }
        );
        const res = await response.json();
        console.log("getMyResultFiti");
        console.log("const res = ");
        console.log(res);
        if (!res || res.length == 0) {
          this.replies = "NO RESULTS FOUND";
        } else if (res.length > 1) {
          console.error("THERE ARE SEVERAL RESULTS FOR THIS USER");
          console.log(res[0].replies);
          this.replies = res[0].replies;
        } else {
          console.log(res[0].replies);
          this.replies = res[0].replies;
        }
      } catch (error) {
        console.log(error);
      }
    },
    hideAllBereiche() {
      this.bereiche.interesse = false;
      this.bereiche.auffassung = false;
      this.bereiche.kooperation = false; 
      this.bereiche.belastbarkeit = false;
      let myDiv = document.getElementById('erklaerung');
      myDiv.scrollTop = 0;
    },
    calculateStanine(replies, berechnung) {
      let score = 0;
      let i = 0;

      while (i < replies.length) {
          score = score + replies[i]*berechnung.summe[i];
          i++;
      }
      score = (score + berechnung.konstante)/replies.length;
      let j = 0;
      while (j < 9) {
          if(score > berechnung.stanine[j]){
            j++;
          }
          else
          {
            break;
          }
      }
      return j+1;
    },
    assignStanine() {
      try {
        const subbereiche = ['A1', 'A2', 'A3', 'B1', 'B2', 'B3', 'C1', 'C2', 'D1', 'D2'];
        let i = 0;
        while(i < subbereiche.length) {
          this.stanine[i].value = this.calculateStanine(this.replies[subbereiche[i]], this.berechnungen[subbereiche[i]]);
          i++;
        }
      } catch(error) {
        console.error(this.replies);
      }
    },
    returnAverageComparison(num) {
      if(num < 4) {
        return "unter dem Durchschnitt";
      }
      else if (num < 7) {
        return "ungefähr im Durchschnitt";
      }
      else {
        return "über dem Durchschnitt";
      }
    },
    returnStanineInterpretation(stanine){
      return "Im Bereich " + stanine.label + ". " + stanine.name + " liegen sie mit einem Stanine-Wert von " + stanine.value + " " + this.returnAverageComparison(stanine.value) + " der Testpopulation.";
    }
  },
  computed: {
    dataset() {
      return {
        type: 'radar',
        data: {
          labels: this.stanine.map(a => a.label.concat(". ", a.name)),
          datasets: [
            {
              label: "Ihr Ergebnis",
              data: this.stanine.map(a => a.value),
              fill: true,
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgb(54, 162, 235)',
              pointBackgroundColor: 'rgb(54, 162, 235)',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgb(54, 162, 235)'
            }
          ]
        },
        options: {
          scales: {
            r: {
              max: 9,
              min: 0,
              pointLabels: {
                font: {
                  size: 15
                }
              }
            }
          }
        }
      };
    }
  }
}
</script>

<style scoped>
.footnote {font-size:x-small;}
.erklaerungsbox {display: flex; flex-direction: row; justify-content: space-evenly; align-items: stretch; border: 1px solid black;  height: 800px; padding-left: 0px}
.erklaerung {overflow: scroll; padding-left: 20px; padding-right: 20px;}
.bereiche {min-width: 400px; width: 20%; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start; border-right: 1px solid black; margin-right: 20px;}
.bereich.active {font-weight: bold; color: #fff; background-color: #00446e;}
.bereich {display: flex; flex-direction: row; height: 24.9%; align-items: center; color: #fbfeff; background-color: #006bac; width: 100%; }
.letter {font-size: 50pt; margin-right: 20px; margin-left: 5px}
.bereichsname {font-size: 15pt; margin-right: 20px;}
.bereich:hover {font-weight: bold; color: #fff; background-color: #00446e;}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
</style>
