<template>
  <div class="container">
    <AssignmentbarFITi @end-assignment="alert('endAssignment')"></AssignmentbarFITi>
    <div class="animate glow row">
      <h2>Doch nun zu Ihrer Selbsteinschätzung:</h2>
      <p>
        Es werden im Folgenden wesentliche Anforderungen an die Person der Informatiker:in in knapper Form beschrieben. Anhand vorgegebener Aussagen sollen Sie beurteilen, inwieweit Sie sich diesen Anforderungen gewachsen fühlen. Zur Einschätzung steht Ihnen eine fünfstufige Skala mit folgenden Ausprägungen zur Verfügung:
      </p>
      <p>
        Die Aussage trifft <strong>völlig auf mich zu</strong>. <br>
        Die Aussage trifft <strong>überwiegend auf mich zu</strong>. <br> 
        Die Aussage trifft <strong>teils-teils auf mich zu</strong>. <br>
        Die Aussage trifft <strong>überwiegend nicht auf mich zu</strong>. <br>
        Die Aussage trifft <strong>überhaupt nicht auf mich zu</strong>.
      </p>
      <p>
        Bitte beachten Sie bei der Auswahl der Skalenstufen, dass die Aussagen zum Teil positiv und zum Teil negativ formuliert sind.
      </p>
      <p>
        Beantworten Sie den Fragebogen <strong>zügig und vollständig</strong> und bemühen Sie sich in Ihrem eigenen Interesse um eine möglichst <strong>realistische Selbsteinschätzung</strong>.
      </p>
      <p>Sollten Sie Ihre Antwort einmal korrigieren wollen, klicken Sie einfach auf das richtige Feld. Nach dem Abschluss der Bearbeitung des Fragebogens können Sie Ihre Antworten allerdings nicht mehr verändern.</p>
      <p>Noch eine Anmerkung: Wenn im Folgenden von „Arbeit“ die Rede ist, bezieht sich das stets auf Ihre aktuelle Tätigkeit (Schule, Ausbildung, Studium, Beruf).</p>
    </div>
    <div class="btn-container">
      <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
      <div class="custom-btn" @click="forwardBtnClicked">Weiter</div>
    </div>
  </div>
</template>

<script>
import AssignmentbarFITi from "@/components/inf/fiti/AssignmentbarFITi.vue";
import * as scrolling from "@/helpers/scroll-helper.js";

export default {
  name: "FITiIntroduction",
  components: {
    AssignmentbarFITi
},
  emits: ["fiti-finished", "explanation-back", "explanation-finished"],
  mounted() {
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" }); 
  },
  data() {
    return {};
  },
  methods: {
    async finishFITi(){
      this.$emit("fiti-finished");
    },
    backBtnClicked() {
      this.$emit("explanation-back");
    },
    forwardBtnClicked() {
      this.$emit("explanation-finished");
    },
  },
};
</script>

<style>
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
</style>
