<template>
  <div>
    <h3>Ergänzung der Selbsteinschätzung durch eine Fremdeinschätzung</h3>
    <p>
      Bei Selbsteinschätzungen - gerade auch, wenn es um so wichtige Fragen wie
      die berufliche Eignung geht - ist das Ergebnis selten objektiv. Einerseits
      besteht die Neigung, sich in zu günstigem Licht zu sehen. Andererseits
      gibt es auch die umgekehrte Tendenz zur überkritischen Sicht auf die
      eigene Person. In beiden Fällen kann das Urteil einer außenstehenden
      Person hilfreich sein, denn es erlaubt den Vergleich von Selbst- und
      Fremdbild.
    </p>
    <p>
      Aus diesem Grunde gibt das Verfahren die Gelegenheit zur
      Fremdeinschätzung. Bitten Sie eine Person, die Sie gut kennt, die
      Fremdeinschätzungsversion von FIT-I zu bearbeiten. Am ehesten dürften hier
      Mitschüler:innen, Studienkolleg:innen, Lehrende, Eltern, ... in Frage
      kommen.
    </p>
    <p>
      Wenn Sie sich nach erfolgter Fremdeinschätzung wieder in das System
      einloggen, finden Sie in der Profildarstellung die grafische
      Gegenüberstellung Ihrer Selbst- und der Fremdeinschätzung. (Die Person,
      die die Fremdeinschätzung durchführt, hat diese Grafik nicht zur
      Verfügung. Wir raten jedoch zu einem Auswertungsgespräch, in dem Sie
      dieser Person auch Ihre Selbsteinschätzung zugänglich machen.)
    </p>
    <p><strong> Hinweise für das Auswertungsgespräch</strong></p>
    <p>
      Die Gegenüberstellung der Profile von Selbst- und Fremdeinschätzung sollte
      nun die Grundlage des Gespräch sein, das Sie mit der Person führen, die
      die Fremdeinschätzung abgegeben hat. Wir empfehlen für das Gespräch ein
      Vorgehen in folgenden 5 Schritten:
    </p>
    <ol>
      <li>
        Bitten Sie die Sie einschätzende Person, die Fremdeinschätzung zu
        erläutern. Dabei ist vor allem wichtig, dass Sie die Begründungen für
        hohe und niedrige Werte erfahren.
      </li>
      <li>
        Erläutern Sie Ihre Selbsteinschätzung (die der Fremdbeurteiler noch
        nicht kennt); legen Sie dabei wiederum besonderen Wert auf die
        Begründung der hohen und niedrigen Ausprägungen. Schauen Sie sich
        gemeinsam die Selbst- und Fremdeinschätzung in jedem der
        berufsübergreifenden und -spezifischen Merkmale an. Stellen Sie fest, wo
        Übereinstimmungen und wo Differenzen bestehen.
      </li>
      <li>
        Arbeiten Sie heraus, wo Übereinstimmungen und wo Differenzen zwischen
        Selbst- und Fremdeinschätzung bestehen. Im Falle der abweichenden
        Einschätzungen sollten Sie gemeinsam nach den möglichen Ursachen suchen.
        (Auf welche Beobachtungen und Erfahrungen stützt sich z. B. das
        jeweilige Urteil? Welche Maßstäbe wurden angelegt?)
      </li>
      <li>
        Fassen Sie nun zusammen, was Sie aus beiden Einschätzungen entnommen
        haben: Wo liegen die Stärken, auf die Sie sich stützen können? Wo gibt
        es Schwachpunkte, die überwunden werden sollten? Orientieren Sie sich
        dabei nicht nur an den einzelnen Merkmalen, sondern auch (und vor allem)
        an den vier übergreifenden Anforderungsbereichen A-D (s. Profil).
      </li>
      <li>
        Ziehen Sie Ihre Schlussfolgerungen und planen Sie geeignete
        Entwicklungsmaßnahmen. Konkrete Anregungen finden Sie im obigen Text.
      </li>
    </ol>

    <div class="fe-link-container">
    <p>Die Fremdeinschätzung können Sie hier bearbeiten: <a :href=linkFe target="_blank">{{ linkFe }}</a>. Eine vollständige Bearbeitung der Fremdeinschätzung wird ungefähr 20 Minuten in Anspruch nehmen.</p>
</div>
    <h4>Meine Fremdeinschätzungen</h4>
    <table class="table">
      <thead>
        <tr>
          <th>Email</th>
          <th>Name</th>
          <th>Code</th>
          <th>Info</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="fe in myFEs" :key="fe.id">
          <td>{{ fe.email }}</td>
          <td>{{ fe.name }}</td>
          <td>{{ fe.code }}</td>
          <td>
            <div class="custom-btn" @click="resendInvitation(fe.id, fe.email, fe.code)">
              Einladung erneut senden
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <FitiFeInvitationForm @fiti-fe-created="addNewFE"></FitiFeInvitationForm>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import FitiFeInvitationForm from "@/components/inf/fiti/FitiFeInvitationForm.vue";

const dataReady = ref(false);
const myFEs = ref({});

const linkFe = ref(process.env.VUE_APP_FE_LINK);

onMounted(async () => {
  await getMyFEs();
  dataReady.value = true;
});

const getMyFEs = async () => {
  try {
    const response = await fetch(
      process.env.VUE_APP_STRAPI_API_URL + "/api/inf-fiti-fes",
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
        },
      }
    );
    const res = await response.json();
        if (!res || res.length == 0) {
          myFEs.value = "NO RESULTS FOUND";
        } else{
          myFEs.value = res.entries;
        }
    return;
  } catch (error) {
    console.log(error);
  }
};

const addNewFE = () => {
  getMyFEs();
};

const resendInvitation = async (feId, feEmail, feCode) => {
  if (feEmail === "" || feCode === "" || feEmail === null || feCode === null) {
    alert("Keine Email vorhanden");
    return;
  }
  let params = {
    feId: feId,
    email: feEmail,
    code: feCode,
  };

  try {
    await fetch(
      process.env.VUE_APP_STRAPI_API_URL + "/api/inf-fiti-fe/resend-invitation-mail",
      {
        method: "POST",
        mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
          },
        body: JSON.stringify({data: params}),
      }
    );
    return;
  } catch (error) {
    console.log(error);
  }
};
</script>

<style scoped>
.fe-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  font-weight: bold;
}
</style>
