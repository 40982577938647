<template>
  <div class="container">
    <AssignmentbarFITi @end-assignment="alert('endAssignment')"
    :showHelpBtn="false"
    :showDismissBtn="true"
    :showFinishBtn="false">
    </AssignmentbarFITi>
    <div class="row">
      <h2>C2. Soziales Geschick und Sensibilität</h2>
      <p>
        Im Rahmen von Informatik-Projekten kommt der Zusammenarbeit mit Personen, die nicht Informatiker:innen sind, große Bedeutung zu. Diese nehmen oftmals eine eigene, ihrem Fachgebiet entsprechende Perspektive ein, die sich von jener der Informatik bisweilen sehr unterscheidet. Gerade in solchen Situationen gilt es, das eigene Fach zu vertreten und sich zu behaupten, aber auch aufeinander zuzugehen und Positionen zusammenzuführen, um auf konstruktive Weise die bestehenden Aufgaben zu lösen. Das erfordert soziales Geschick und Sensibilität.
      </p>
        <div class = "survey">
          <div class="survey-row header">
          <div class = "question">Wie ist das bei Ihnen?</div>
          <div class = "reply">trifft völlig auf mich zu</div>
          <div class = "reply">trifft überwiegend auf mich zu</div>
          <div class = "reply">trifft teilsteils auf mich zu</div>
          <div class = "reply">trifft überwiegend nicht auf mich zu</div>
          <div class = "reply">trifft überhaupt nicht auf mich zu</div>
        </div>

        <div class="survey-row light">
          <div class = "question">Ich habe ein ausgeprägtes Gespür für andere Menschen.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q1"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Ich gehöre zu den Menschen, die über viel soziales Geschick verfügen.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q2"></div>
        </div>

        <div class="survey-row light">
          <div class = "question">Ich finde in der Arbeit schnell einen "guten Draht" zu anderen.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q3"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Es passiert mir immer wieder, dass ich andere kränke.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q4"></div>
        </div>

        <div class="survey-row light">
          <div class = "question">Es gelingt mir gut, Spannungen in einer Gruppe auszugleichen. </div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q5"></div>
        </div>

    </div>
    <div class="btn-container">
      <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
      <div class="custom-btn" @click="forwardBtnClicked" v-if="isComplete">Weiter</div>
    </div>
  </div>
</div>
</template>

<script>
import AssignmentbarFITi from "@/components/inf/fiti/AssignmentbarFITi.vue";
import * as scrolling from "@/helpers/scroll-helper.js";
import store from "@/store";

export default {
  name: "FITiIntroduction",
  components: {
    AssignmentbarFITi
},
  emits: ["fiti-finished", "C2-back", "C2-finished"],
  mounted() {
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" });
    this.fragen.Q1 = this.$store.state.fitiModule.replies.C2[0];
    this.fragen.Q2 = this.$store.state.fitiModule.replies.C2[1];
    this.fragen.Q3 = this.$store.state.fitiModule.replies.C2[2];
    this.fragen.Q4 = this.$store.state.fitiModule.replies.C2[3];
    this.fragen.Q5 = this.$store.state.fitiModule.replies.C2[4]; 
  },
  data() {
    return {
      fragen: {
        Q1: null,
        Q2: null,
        Q3: null,
        Q4: null,
        Q5: null
      }
    };
  },
  methods: {
    async finishFITi(){
      this.$emit("fiti-finished");
    },
    backBtnClicked() {
      this.$emit("C2-back");
    },
    forwardBtnClicked() {
      this.logUpdate(this.fragen);
      this.$emit("C2-finished");
    },
    logUpdate(data) {
      store.commit("fitiC2Update", data);
    }
  },
  computed: {
    isComplete() {
        return this.fragen.Q1 && this.fragen.Q2 && this.fragen.Q3 && this.fragen.Q4 && this.fragen.Q5;
    }
  }
}
</script>

<style>
  .questions {
  display: grid;
  grid-template-columns: 400px 100px 100px 100px 100px 100px;
  grid-gap: 10px;
  background-color: #fff;
  color: #444;
}
</style>
