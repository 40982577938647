<template>
  <div class="container">
    <AssignmentbarFITi @end-assignment="alert('endAssignment')"
    :showHelpBtn="false"
    :showDismissBtn="true"
    :showFinishBtn="false">
    </AssignmentbarFITi>
    <div class="row">
      <h2>A1: Neugier und Begeisterungsfähigkeit</h2>
      <p>
        Informatiker:innen entwickeln Werkzeuge und Lösungen für die unterschiedlichsten Arbeitsfelder und Erfordernisse. Das erfordert die Bereitschaft, sich mit fremden Themen und Arbeitsweisen gründlich auseinanderzusetzen. Hilfreich sind dabei Neugier und Begeisterungsfähigkeit.
      </p>
        <div class = "survey">
          <div class="survey-row header">
            <div class = "question">Wie ist das bei Ihnen?</div>
            <div class = "reply">trifft völlig auf mich zu</div>
            <div class = "reply">trifft überwiegend auf mich zu</div>
            <div class = "reply">trifft teilsteils auf mich zu</div>
            <div class = "reply">trifft überwiegend nicht auf mich zu</div>
            <div class = "reply">trifft überhaupt nicht auf mich zu</div>
          </div>
          <div class="survey-row light">
            <div class = "question">Ich liebe es, knifflige Probleme zu durchdenken.</div>
            <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q1"></div>
            <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q1"></div>
            <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q1"></div>
            <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q1"></div>
            <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q1"></div>
          </div>
          <div class="survey-row dark">
            <div class = "question">Für neue Entwicklungen und Trends kann ich mich schnell begeistern.</div>
            <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q2"></div>
            <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q2"></div>
            <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q2"></div>
            <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q2"></div>
            <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q2"></div>
          </div>         
          <div class="survey-row light">
            <div class = "question">Herausfordernde Aufgaben und Inhalte spornen mich sehr an.</div>
            <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q3"></div>
            <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q3"></div>
            <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q3"></div>
            <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q3"></div>
            <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q3"></div>
          </div>
            
          <div class="survey-row dark">
            <div class = "question">Es macht mir Freude, mich mit mir unbekannten Themen zu beschäftigen.</div>
            <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q4"></div>
            <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q4"></div>
            <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q4"></div>
            <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q4"></div>
            <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q4"></div>
          </div>
            
          <div class="survey-row light">
            <div class = "question">Begeisterung für Bereiche außerhalb meines Interessenprofils kann ich nur schwer entwickeln.</div>
            <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q5"></div>
            <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q5"></div>
            <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q5"></div>
            <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q5"></div>
            <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q5"></div>
          </div>
            
          <div class="survey-row dark">
            <div class = "question">Neue Themen sind mir immer eine willkommene Abwechslung.</div>
            <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q6"></div>
            <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q6"></div>
            <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q6"></div>
            <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q6"></div>
            <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q6"></div>
          </div>
        </div>
    </div>
    <div class="btn-container">
      <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
      <div class="custom-btn" @click="forwardBtnClicked" v-if="isComplete">Weiter</div>
    </div>
  </div>
</template>

<script>
import AssignmentbarFITi from "@/components/inf/fiti/AssignmentbarFITi.vue";
import * as scrolling from "@/helpers/scroll-helper.js";
import store from "@/store";

export default {
  name: "FITiIntroduction",
  components: {
    AssignmentbarFITi
},
  emits: ["fiti-finished", "A1-back", "A1-finished"],
  mounted() {
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" }); 
    this.fragen.Q1 = this.$store.state.fitiModule.replies.A1[0];
    this.fragen.Q2 = this.$store.state.fitiModule.replies.A1[1];
    this.fragen.Q3 = this.$store.state.fitiModule.replies.A1[2];
    this.fragen.Q4 = this.$store.state.fitiModule.replies.A1[3];
    this.fragen.Q5 = this.$store.state.fitiModule.replies.A1[4];
    this.fragen.Q6 = this.$store.state.fitiModule.replies.A1[5];
  },
  data() {
    return {
      fragen: {
        Q1: null,
        Q2: null,
        Q3: null,
        Q4: null,
        Q5: null,
        Q6: null
      }
    };
  },
  methods: {
    async finishFITi(){
      this.$emit("fiti-finished");
    },
    backBtnClicked() {
      this.$emit("A1-back");
    },
    forwardBtnClicked() {
      this.unfinished = false;
      this.logUpdate(this.fragen);
      this.$emit("A1-finished");
    },
    logUpdate(data) {
      store.commit("fitiA1Update", data);
    }
  },
  computed: {
    isComplete() {
      return this.fragen.Q1 && this.fragen.Q2 && this.fragen.Q3 && this.fragen.Q4 && this.fragen.Q5 && this.fragen.Q6;
    }
  }
}
</script>

<style>
  .survey {
  display: flex;
  flex-direction: column;
  line-height: 1.3;
  }
  .survey-row {
    display: flex;
    flex-direction: row;
  }
  .question {
    min-width: 100px;
    width: 25%;
    max-width: 500px;
    line-height: 1.6;
  }
  .reply {
    min-width: 100px;
    width: 15%;
    max-width: 300px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .dark {
    background-color: rgb(173, 201, 230);
  }
  .light{
    background-color: rgb(248, 248, 248);
  }
  .header {
    background: rgb(70, 110, 165);
    color: white;
  }
  p{
  line-height: 1.3;
}
</style>
