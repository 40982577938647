<template>
  <div class="container">
    <AssignmentbarFITi @end-assignment="alert('endAssignment')"
    :showHelpBtn="false"
    :showDismissBtn="true"
    :showFinishBtn="false">
    </AssignmentbarFITi>
    <div class="row">
      <h2>A2: Gewissenhaftigkeit und Genauigkeit</h2>
      <p>
        Denkfehler, unsauberes Arbeiten, unvollständige Dokumentation, kleinere und größere Nachlässigkeiten bringen in der Informatik nicht nur erhöhten Arbeitsaufwand und Mehrkosten mit sich. Sie können zu schweren erheblichen materiellen Schäden führen oder im schlimmsten Fall sogar Menschenleben gefährden. Gerade in der Informatik sind deshalb Gewissenhaftigkeit und Genauigkeit in hohem Maße erforderlich. 
      </p>
      <div class = "survey">
        <div class="survey-row header">
          <div class = "question">Wie ist das bei Ihnen?</div>
          <div class = "reply">trifft völlig auf mich zu</div>
          <div class = "reply">trifft überwiegend auf mich zu</div>
          <div class = "reply">trifft teilsteils auf mich zu</div>
          <div class = "reply">trifft überwiegend nicht auf mich zu</div>
          <div class = "reply">trifft überhaupt nicht auf mich zu</div>
        </div>

        <div class="survey-row light">
          <div class = "question">Ich bin ein äußerst genau arbeitender Mensch.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q1"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">An meinen Arbeitsergebnissen ist ablesbar, dass ich hohe Ansprüche habe.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q2"></div>
        </div>

        <div class="survey-row light">
          <div class = "question">In meiner Arbeit fehlt es oftmals an Feinschliff.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q3"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Ich achte sehr auf Genauigkeit im Detail.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q4"></div>
        </div>

        <div class="survey-row light">
          <div class = "question">Ich lege viel Wert darauf, dass meine Arbeit perfekt ist. </div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q5"></div>
        </div>

    </div>
    <div class="btn-container">
      <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
      <div class="custom-btn" @click="forwardBtnClicked" v-if="isComplete">Weiter</div>
    </div>
  </div>
</div>
</template>

<script>
import AssignmentbarFITi from "@/components/inf/fiti/AssignmentbarFITi.vue";
import * as scrolling from "@/helpers/scroll-helper.js";
import store from "@/store";

export default {
  name: "FITiIntroduction",
  components: {
    AssignmentbarFITi
},
  emits: ["fiti-finished", "A2-back", "A2-finished"],
  mounted() {
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" }); 
    this.fragen.Q1 = this.$store.state.fitiModule.replies.A2[0];
    this.fragen.Q2 = this.$store.state.fitiModule.replies.A2[1];
    this.fragen.Q3 = this.$store.state.fitiModule.replies.A2[2];
    this.fragen.Q4 = this.$store.state.fitiModule.replies.A2[3];
    this.fragen.Q5 = this.$store.state.fitiModule.replies.A2[4];
  },
  data() {
    return {
      fragen: {
        Q1: null,
        Q2: null,
        Q3: null,
        Q4: null,
        Q5: null
      }
    };
  },
  methods: {
    async finishFITi(){
      this.$emit("fiti-finished");
    },
    backBtnClicked() {
      this.$emit("A2-back");
    },
    forwardBtnClicked() {
      this.logUpdate(this.fragen);
      this.$emit("A2-finished");
    },
    logUpdate(data) {
      store.commit("fitiA2Update", data);
    }
  },
  computed: {
    isComplete() {
      return this.fragen.Q1 && this.fragen.Q2 && this.fragen.Q3 && this.fragen.Q4 && this.fragen.Q5;
    }
  }
}
</script>

<style>
  .survey {
  display: flex;
  flex-direction: column;
  }
  .survey-row {
    display: flex;
    flex-direction: row;
  }
  .question {
    min-width: 100px;
    width: 25%;
    max-width: 500px;
  }
  .reply {
    min-width: 100px;
    width: 15%;
    max-width: 300px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .dark {
    background-color: rgb(173, 201, 230);
  }
  .light{
    background-color: rgb(248, 248, 248);
  }
  .header {
    background: rgb(70, 110, 165);
    color: white;
    justify-content: space-between;
    gap: 20px;
  }
</style>