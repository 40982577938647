<template>
  <div class="container">
    <AssignmentbarFITi @end-assignment="alert('endAssignment')"
    :showHelpBtn="false"
    :showDismissBtn="true"
    :showFinishBtn="false">
    </AssignmentbarFITi>
    <div class="row">
      <h2>B3. Fähigkeit zum effizienten Arbeiten</h2>
      <p>
        Im Informatikstudium hat man ein umfangreiches Aufgabenpensum zu erledigen. Das betrifft vor allem die Vor- und Nachbereitung der Lehrveranstaltungen und Übungen, das Literaturstudium, das Erstellen von Abgaben für praktische Arbeiten oder Implementierungen sowie das selbstständige Verfassen von Texten. Man muss deshalb in der Lage sein, gut organisiert, rationell und diszipliniert zu arbeiten. 
      </p>
      <div class = "survey">
        <div class="survey-row header">
          <div class = "question">Wie ist das bei Ihnen?</div>
          <div class = "reply">trifft völlig auf mich zu</div>
          <div class = "reply">trifft überwiegend auf mich zu</div>
          <div class = "reply">trifft teilsteils auf mich zu</div>
          <div class = "reply">trifft überwiegend nicht auf mich zu</div>
          <div class = "reply">trifft überhaupt nicht auf mich zu</div>
        </div>

        <div class="survey-row light">
          <div class = "question">Ich verstehe es gut, mir die Arbeit einzuteilen.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q1"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q1"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Wenn mehrere Aufgaben zu bewältigen sind, fällt es mir schwer, Prioritäten zu setzen.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q2"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q2"></div>
        </div>

        <div class="survey-row light">
          <div class = "question">Es gelingt mir sehr gut, mit meiner Arbeit in der vorgegebenen Zeit fertig zu werden.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q3"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q3"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Ich fürchte, dass ich nicht rationell genug arbeite.</div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q4"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q4"></div>
        </div>

        <div class="survey-row light">
          <div class = "question">Ich gerate fast nie in Zeitdruck. </div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q5"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q5"></div>
        </div>

        <div class="survey-row dark">
          <div class = "question">Ich lasse mich leicht von meiner Arbeit ablenken. </div>
          <div class = "reply"><input type = "radio" value = "5" v-model = "fragen.Q6"></div>
          <div class = "reply"><input type = "radio" value = "4" v-model = "fragen.Q6"></div>
          <div class = "reply"><input type = "radio" value = "3" v-model = "fragen.Q6"></div>
          <div class = "reply"><input type = "radio" value = "2" v-model = "fragen.Q6"></div>
          <div class = "reply"><input type = "radio" value = "1" v-model = "fragen.Q6"></div>
        </div>

    </div>
    <div class="btn-container">
      <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
      <div class="custom-btn" @click="forwardBtnClicked" v-if="isComplete">Weiter</div>
    </div>
  </div>
</div>
</template>

<script>
import AssignmentbarFITi from "@/components/inf/fiti/AssignmentbarFITi.vue";
import * as scrolling from "@/helpers/scroll-helper.js";
import store from "@/store";

export default {
  name: "FITiIntroduction",
  components: {
    AssignmentbarFITi
},
  emits: ["fiti-finished", "B3-back", "B3-finished"],
  mounted() {
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" }); 
    this.fragen.Q1 = this.$store.state.fitiModule.replies.B3[0];
    this.fragen.Q2 = this.$store.state.fitiModule.replies.B3[1];
    this.fragen.Q3 = this.$store.state.fitiModule.replies.B3[2];
    this.fragen.Q4 = this.$store.state.fitiModule.replies.B3[3];
    this.fragen.Q5 = this.$store.state.fitiModule.replies.B3[4]; 
    this.fragen.Q6 = this.$store.state.fitiModule.replies.B3[5];
  },
  data() {
    return {
      fragen: {
        Q1: null,
        Q2: null,
        Q3: null,
        Q4: null,
        Q5: null,
        Q6: null
      }
    };
  },
  methods: {
    async finishFITi(){
      this.$emit("fiti-finished");
    },
    backBtnClicked() {
      this.$emit("B3-back");
    },
    forwardBtnClicked() {
      this.logUpdate(this.fragen);
      this.$emit("B3-finished");
    },
    logUpdate(data) {
      store.commit("fitiB3Update", data);
    }
  },
  computed: {
    isComplete() {
        return this.fragen.Q1 && this.fragen.Q2 && this.fragen.Q3 && this.fragen.Q4 && this.fragen.Q5 && this.fragen.Q6;
      }
  }
}
</script>

<style>
  .survey {
  display: flex;
  flex-direction: column;
  }
  .survey-row {
    display: flex;
    flex-direction: row;
  }
  .question {
    min-width: 100px;
    width: 25%;
    max-width: 500px;
  }
  .reply {
    min-width: 100px;
    width: 15%;
    max-width: 300px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .dark {
    background-color: rgb(173, 201, 230);
  }
  .light{
    background-color: rgb(248, 248, 248);
  }
  .header {
    background: rgb(70, 110, 165);
    color: white;
  }
</style>